import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/call',
    name: 'call',
    component: () => import('../views/CallCenterView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['call']
    }
  },
  {
    path: '/stats',
    name: 'HomeMobileView',
    component: () => import('../views/HomeMobileView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/notifications',
    name: 'NotificationsView',
    component: () => import('../views/NotificationsView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/ProfileView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker', 'call']
    }
  },
  {
    path: '/qr',
    name: 'QR',
    component: () => import('../views/QRView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/audit-log',
    name: 'AuditLog',
    component: () => import('../views/AuditLogView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/shop-treasury',
    name: 'ShopTreasury',
    component: () => import('../views/ShopTreasuryView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/shop-treasury/cash-desk',
    name: 'CashDesk',
    component: () => import('../views/treasury/CashDeskView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/shop-treasury/expenditure',
    name: 'Expenditure',
    component: () => import('../views/treasury/ExpenditureView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/GoodsView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/CustomersView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/employees',
    name: 'Employees',
    component: () => import('../views/EmployeesView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'manager']
    }
  },
  {
    path: '/employees/new',
    name: 'EmployeesNew',
    component: () => import('../views/EmployeesNewView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'manager']
    }
  },
  {
    path: '/employees/edit/:id',
    name: 'EmployeesEditNew',
    component: () => import('../views/EmployeesEditView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'manager']
    }
  },
  {
    path: '/product-groups',
    name: 'ProductGroups',
    component: () => import('../views/ProductGroups.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/:id',
    name: 'ProductGroupsId',
    component: () => import('../views/ProductGroupsId.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/:id/new',
    name: 'ProductGroupsNewId',
    component: () => import('../views/ProductGroupsNewId.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/:id/:elem',
    name: 'ProductGroupsEdId',
    component: () => import('../views/ProductGroupsEdId.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/edit/:id',
    name: 'ProductGroupsEditId',
    component: () => import('../views/ProductGroupsEditId.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/new',
    name: 'ProductGroupsNew',
    component: () => import('../views/ProductNewGroups.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/product-groups/price/:id',
    name: 'PriceView',
    component: () => import('../views/PriceView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/sales-plans',
    name: 'SalesPlans',
    component: () => import('../views/SalesPlansView.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('../views/applications/AppMain.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/main-warehouse-check',
    component: () => import('../views/inventory/MainWarehouseCheck.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/main-warehouse',
    component: () => import('../views/applications/MainWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker'],
    }
  },
  {
    path: '/applications/getting-battery-stock',
    component: () => import('../views/applications/GettingBatteryStock.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/old-batteries',
    component: () => import('../views/applications/OldBatteries.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/other-stores',
    component: () => import('../views/applications/OtherStores.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/defective-warehouse',
    component: () => import('../views/applications/DefectiveWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin','manager']
    }
  },
  {
    path: '/applications/per-driver',
    component: () => import('../views/applications/PerDriver.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/driver-inshop',
    component: () => import('../views/applications/DriverInShop.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/applications/store-warehouse',
    component: () => import('../views/applications/StoreWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/services/ServicesMain.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/sale-batteries',
    component: () => import('../views/services/SaleBatteries.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/admission-marriage',
    component: () => import('../views/services/AdmissionMarriage.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/batteries-for-service',
    component: () => import('../views/services/BatteriesForService.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/acceptance-defective',
    component: () => import('../views/services/AcceptanceDefective.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/acceptance-old-batteries',
    component: () => import('../views/services/AcceptanceOldBatteries.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/services/acceptance-batteries-service',
    component: () => import('../views/services/AcceptanceBatteriesService.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory-control',
    name: 'Inventory',
    component: () => import('../views/inventory/InventoryMain.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/main-warehouse',
    component: () => import('../views/inventory/MainWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/main-driver',
    component: () => import('../views/inventory/MainDriver.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/defective-battery',
    component: () => import('../views/inventory/DefectiveBattery.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/old-batteries',
    component: () => import('../views/inventory/OldBatteries.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/temporary-warehouse',
    component: () => import('../views/inventory/TemporaryWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/shop-warehouse',
    component: () => import('../views/inventory/ShopWarehouse1.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/shop-warehouse/:id',
    component: () => import('../views/inventory/InventoryId.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/scrap-warehouse',
    component: () => import('../views/inventory/ScrapWarehouse.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/inventory/remains-shops',
    component: () => import('../views/inventory/RemainsShops.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin', 'stockman', 'manager', 'courier', 'worker']
    }
  },
  {
    path: '/reference',
    meta: {
      layout: 'main',
      requiresAuth: true,
      allowedRoles: ['superadmin', 'admin']
    },
    children: [
      {
        path: 'roles',
        component: () => import('../views/reference/RolesView.vue'),
      },
      {
        path: 'branches',
        component: () => import('../views/reference/BranchesView.vue')
      },
      {
        path: 'branches/:id',
        component: () => import('../views/reference/BranchesShopsView.vue')
      },
      {
        path: 'areas',
        component: () => import('../views/reference/AreasView.vue'),
      },
      {
        path: 'areas/:id',
        component: () => import('../views/reference/AreasLocalityView.vue'),
      },
      {
        path: 'applications-statuses',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Статусы заявок',
          api: 'app_statuses',
          edit: false
        }
      },
      {
        path: 'applications-types',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Виды заявок',
          api: 'app_types',
          edit: false
        }
      },
      {
        path: 'brands',
        component: () => import('../views/reference/BrandsView.vue'),
        meta: {
          title: 'Бренды',
          api: 'brands',
          edit: true
        }
      },
      {
        path: 'manufacturer-country',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Страна производитель',
          api: 'manufacturer_country',
          edit: true
        }
      },
      {
        path: 'types-services',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Виды услуг',
          api: 'service_types',
          edit: false
        }
      },
      {
        path: 'payment-methods',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Способы оплаты',
          api: 'payment_types',
          edit: true
        }
      },
      {
        path: 'values-discounts',
        component: () => import('../views/reference/ValuesView.vue')
      },
      {
        path: 'types-discounts',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Виды скидок',
          api: 'discount_types',
          edit: false
        }
      },
      {
        path: 'types-identity-documents',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Виды документов, удостоверяющих личность',
          api: 'document_types',
          edit: true
        }
      },
      {
        path: 'authorities-issuing',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Органы выдачи документов, удостоверяющих личность',
          api: 'authorities_types',
          edit: true
        }
      },
      {
        path: 'return-type',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Тип возврата',
          api: 'return_types',
          edit: true
        }
      },
      {
        path: 'payment-state',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Статус оплаты',
          api: 'payment_statuses',
          edit: true
        }
      },
      {
        path: 'basis-expenditure',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Основание расхода из Казны',
          api: 'expenditure',
          edit: true
        }
      },
      {
        path: 'service-statuses',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Статусы услуг',
          api: 'service_statuses',
          edit: true
        }
      },
      {
        path: 'guarantee-period',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Гарантийный срок',
          api: 'guarantee_period',
          edit: true
        }
      },
      {
        path: 'cars',
        component: () => import('../views/reference/DinamycReferences.vue'),
        meta: {
          title: 'Наименование автомобилей',
          api: 'name_cars',
          edit: true
        }
      }
    ]
  },
  {
    path: '/auth/:slug',
    name: 'authSlug',
    component: () => import('../views/AuthView.vue'),
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      layout: 'error'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const userRole = store.getters.getRole;
    const allowedRoles = to.meta.allowedRoles;
    if (allowedRoles.includes(userRole)) {
      next()
       // User role is allowed, proceed to the route
    } else {
      next('/auth/login'); // User role is not allowed, redirect to unauthorized page or handle as needed
    }
  } else if (to.name === 'authSlug' && store.getters.getRole) {
    if (store.getters.getRole === 'call') {
      next('/call');
    } else {
      next('/');
    }
     // User is logged in, redirect to the dashboard or another appropriate page
  } else {
    next(); // Route does not require authentication
  }
});


export default router
