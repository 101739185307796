import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import QrReader from 'vue3-qr-reader';
import vClickOutside from "click-outside-vue3"
import VueGoodTablePlugin from 'vue-good-table-next';
import VueSelect from "vue-select";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import VueAwesomePaginate from "vue-awesome-paginate";

import 'vue-good-table-next/dist/vue-good-table-next.css'
import 'vue-select/dist/vue-select.css'
import './registerServiceWorker'

const timeUTC = (date) => {
 const dateObj = new Date(date);
 dateObj.setHours(dateObj.getHours() + 6);
 return `${String(dateObj.getUTCDate()).padStart(2, '0')}.${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}.${dateObj.getUTCFullYear()} ${String(dateObj.getUTCHours()).padStart(2, '0')}:${String(dateObj.getUTCMinutes()).padStart(2, '0')}`;
};


const axiosInstance = axios.create({
 baseURL: 'https://back-voltman.test-vip.kz/api/'
 // baseURL: 'http://192.168.0.111:3012/api/'
 // baseURL: 'http://localhost:3012/api/'
})
axios.defaults.baseURL = 'https://back-voltman.test-vip.kz/api/'
// axios.defaults.baseURL = 'http://192.168.0.111:3012/api/'
// axios.defaults.baseURL = 'http://localhost:3012/api/'

const app = createApp(App).use(store).use(QrReader).use(router).use(vClickOutside).use(VueGoodTablePlugin).use(VCalendar, {}).use(VueAwesomePaginate)
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$toaster = createToaster({ position: "top-right", duration: 1900 })
app.config.globalProperties.$timeUTC = timeUTC;
app.config.globalProperties.$cdn = 'https://back-voltman.test-vip.kz'
// app.config.globalProperties.$cdn = 'http://192.168.0.111:3012'
// app.config.globalProperties.$cdn = 'http://localhost:3012'
app.component("v-select", VueSelect)
app.mount('#app')


 